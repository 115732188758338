import { useRouter } from '#imports'

import { MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { insertIf } from '@backmarket/utils/collection/insertIf'

import { STEP_NAMES } from '~/scopes/buyback/constants'
import addressTranslations from '~/scopes/buyback/pages/Address.translations'
import bankTranslations from '~/scopes/buyback/pages/Bank.translations'
import shippingTranslations from '~/scopes/buyback/pages/Shipping.translations'

interface useResaleArgs {
  activeStepName: (typeof STEP_NAMES)[keyof typeof STEP_NAMES]
  hasShippingStep?: boolean
}

interface step {
  label: string
  name: (typeof STEP_NAMES)[keyof typeof STEP_NAMES]
}

export const useResaleStepper = ({
  activeStepName,
  hasShippingStep = false,
}: useResaleArgs) => {
  const country: MarketCountryCode = useMarketplace().market.countryCode
  const i18n = useI18n()
  const router = useRouter()

  const hasBankStep = [
    MarketCountryCode.FR,
    MarketCountryCode.DE,
    MarketCountryCode.GB,
    MarketCountryCode.ES,
  ].includes(country)

  const steps: Array<step> = [
    ...insertIf(hasBankStep, [
      {
        label: i18n(bankTranslations.headerTitle),
        name: STEP_NAMES.BANK,
      },
    ]),
    {
      label: i18n(addressTranslations.headerTitle),
      name: STEP_NAMES.ADDRESS,
    },
    ...insertIf(hasShippingStep, [
      {
        label: i18n(shippingTranslations.headerTitle),
        name: STEP_NAMES.SHIPPING,
      },
    ]),
  ]

  const activeStepIndex = steps.findIndex(
    (step) => step.name === activeStepName,
  )

  const hasBackButton = activeStepIndex > 0

  const goBack = () => {
    if (hasBackButton) {
      router.back()
    }
  }

  return {
    steps,
    hasBackButton,
    goBack,
  }
}
