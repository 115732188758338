export default {
  headerTitle: {
    id: 'buyback_bank_header_stepper',
    defaultMessage: 'Bank information',
  },
  formTitle: {
    id: 'buyback_bank_form_title',
    defaultMessage: 'Nous en avons besoin pour vous payer',
  },
  formDescription: {
    id: 'buyback_bank_form_description',
    defaultMessage:
      'Comme ça on pourra verser {price, number, currency} directement sur votre compte en banque. Bim, bam, boum !',
  },
  buttonLabel: {
    id: 'buyback_bank_form_submit',
    defaultMessage: 'Submit',
  },
  previousPage: {
    id: 'buyback_common_previous_page',
    defaultMessage: 'Back to previous page',
  },
  stepperClose: {
    id: 'buyback_common_stepper_close',
    defaultMessage: 'Close',
  },
  stepperCompleted: {
    id: 'buyback_common_stepper_completed',
    defaultMessage: 'Completed',
  },
  stepperCurrent: {
    id: 'buyback_common_stepper_current',
    defaultMessage: 'Current',
  },
}
